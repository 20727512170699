import {
  BlogAnnouncementServerResponseType,
  BlogCasesDetailsResponseType,
  BlogEventsServerResponseType,
  BlogGetUsersServerResponseType,
  BlogNewsDetailServerResponseType,
  BlogPostSuggestionParamsType,
  BlogPostSuggestionServerResponseType,
  BlogTagsDictionaryResponseType,
} from './blogServerResponse';
import instanceAxios from './instanceAxios';

export const blogApi = {
  async getAllBlogEvents(query?: string) {
    const response = await instanceAxios.get<BlogEventsServerResponseType>(`blog${query ? query : ''}`);
    return response.data;
  },

  async getBlogNewsDetailById(id: string) {
    const response = await instanceAxios.get<BlogNewsDetailServerResponseType>(`news/${id}`);
    return response.data;
  },

  async getBlogAnnounceDetailById(id: string) {
    const response = await instanceAxios.get<BlogAnnouncementServerResponseType>(`pbs/dgp-announcements/${id}`);
    return response.data;
  },

  async getBlogCaseDetailsById(id: string) {
    const response = await instanceAxios.get<BlogCasesDetailsResponseType>(`/best-practices/${id}`);
    return response.data;
  },

  async getBlogTagsDictionary() {
    const response = await instanceAxios.get<BlogTagsDictionaryResponseType>(`/tags`);
    return response.data;
  },
  async getDigests() {
    const response = await instanceAxios.get(`/digests`);
    return response.data;
  },

  async getBlogUsers() {
    const response = await instanceAxios.get<BlogGetUsersServerResponseType>(`/pbs/dct-person`);
    return response.data;
  },

  async postBlogSuggestion(params: BlogPostSuggestionParamsType) {
    const response = await instanceAxios.post<BlogPostSuggestionServerResponseType>(`/suggestions`, params);
    return response.data;
  },
  async postBlogComment(value: string, id: string) {
    const response = await instanceAxios.post(`/news/${id}/comment`, { value: value });
    return response.data;
  },
};
