import React, { useCallback } from 'react';

import { BlogAnnouncementType } from '../../../../../api/blogServerResponse';
import BlogShareBlock from '../../../../../Components/BlogShareBlock/BlogShareBlock';
import BlogSubscribeBlock from '../../../../../Components/BlogSubscribeBlock/BlogSubscribeBlock';
import classes from './RightColumn.module.css';
import { Button } from '@consta/uikit/Button';
import { useNavigate } from 'react-router-dom';
import { useAppDispatch } from '../../../../../hooks/hooks';
import { publishBlogNewsThunk } from '../../../../../store/adminBlogSlice';

interface IRightColumnProps {
  announce: BlogAnnouncementType | undefined;
}

const RightColumn: React.FC<IRightColumnProps> = ({ announce }) => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const publish = useCallback(() => {
    dispatch(publishBlogNewsThunk(announce?._uuid)).then(() => {
      window.location.reload();
    });
  }, [announce]);
  return (
    <div className={classes.container}>
      {announce?.status?.slug === 'draft' && (
        <div className={classes.buttons}>
          <Button view={'primary'} label={'Опубликовать'} onClick={publish} />
          <Button
            view={'secondary'}
            label={'Редактировать'}
            onClick={() => navigate(`/admin/blog/news/${announce._uuid}`)}
          />
        </div>
      )}
      <BlogSubscribeBlock announce={announce} />
      <BlogShareBlock title={`Знаете, кому будет интересен этот\nанонс? Отправьте ссылку на почту в\nодин клик`} />
      {/*<div className={classes.growWrapper}>
        <div style={{ transform: 'translateY(40px)' }}>
          <BlogSubscribeBlock announce={announce} />
        </div>
      </div>*/}
    </div>
  );
};

export default RightColumn;
