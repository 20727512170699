import { SortingItemType } from '../Pages/Admin/AdminBlogPage/AdminBlogPageHeader/AdminBlogPageActionRow/SortingSelector/SortingSelector';
import { selectorViewSelectorActiveSortingId } from '../store/adminBlogSlice';
import { getQueryPagination } from '../utils/adminBlog/getQueryPagination';
import { getQueryPaginationFromFirstPage } from '../utils/adminBlog/getQueryPaginationFromFirstPage';
import { getQuerySearchValue } from '../utils/adminBlog/getQuerySearchValue';
import { getQuerySortValue } from '../utils/adminBlog/getQuerySortValue';
import { combineQuery } from '../utils/combineQuery';
import { useAppDispatch, useAppSelector } from './hooks';
import { getQueryInArchive } from '../utils/adminBlog/getQueryInArchive';
import {
  getAdminMethodologyProcessesThunk,
  selectorAdminMethodoFilter,
  selectorAdminMethodologyProcessItems,
  selectorMethodologyViewSelectorActiveSortingId,
  selectorMethodologyViewSelectorInArchive,
  selectorMethodologyViewSelectorSearchValue,
} from '../store/adminMethodologySlice';

interface useGetBlogItemsType {
  searchValue?: string | null | undefined;
  queryPagination?: string | undefined;
  sortingItem?: SortingItemType;
  inArchive?: boolean;
  filterValue?: string;
}

export const useGetProcesses = () => {
  const dispatch = useAppDispatch();
  const searchValueStore = useAppSelector(selectorMethodologyViewSelectorSearchValue);
  const inArchiveValueStore = useAppSelector(selectorMethodologyViewSelectorInArchive);
  const paginationValueStore = useAppSelector(selectorAdminMethodologyProcessItems)?.meta;
  const activeSortingIdStore = useAppSelector(selectorMethodologyViewSelectorActiveSortingId);
  const filterValueStore = useAppSelector(selectorAdminMethodoFilter);

  return ({ searchValue, queryPagination, sortingItem, inArchive, filterValue }: useGetBlogItemsType) => {
    const querySearchValue =
      searchValue === null ? '' : getQuerySearchValue(searchValue ? searchValue : searchValueStore);

    const queryInArchiveValue = getQueryInArchive(inArchive ? inArchive : inArchiveValueStore);
    // console.log(queryInArchiveValue);
    const queryPaginationValue = queryPagination ? queryPagination : getQueryPagination(paginationValueStore);
    const querySortingValue = sortingItem
      ? getQuerySortValue(sortingItem.id, 'process')
      : getQuerySortValue(activeSortingIdStore, 'process');

    const filter = !!filterValue ? filterValue : filterValueStore;
    const requestQuery = combineQuery(
      querySearchValue,
      querySortingValue,
      queryPaginationValue ? queryPaginationValue : getQueryPaginationFromFirstPage(paginationValueStore),
      queryInArchiveValue,
      filter
    );

    dispatch(getAdminMethodologyProcessesThunk(requestQuery));
  };
};
