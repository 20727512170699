import React, { useCallback, useEffect, useState } from 'react';

import classes from './AdminSuggestionTableActions.module.css';
import { Button } from '@consta/uikit/Button';
import { IconEdit } from '@consta/uikit/IconEdit';
import { IconStorage } from '@consta/uikit/IconStorage';
import { IconTrash } from '@consta/uikit/IconTrash';
import { IconInfo } from '@consta/uikit/IconInfo';
import { useDispatch } from 'react-redux';
import { useAppDispatch, useAppSelector } from '../../../../../../hooks/hooks';
import {
  archiveDigestThunk,
  archiveSuggestionThunk,
  deleteSuggestionThunk,
  getAdminBlogSuggestionThunk,
  getSuggestionStatusesThunk,
  selectorSuggestionStatuses,
  selectorViewSelectorInArchive,
  updateSuggestionThunk,
} from '../../../../../../store/adminBlogSlice';
import BlogModalDelete from '../../../BlogModalDelete/BlogModalDelete';
import { pushSnackBarItemReports } from '../../../../../../store/appSlice';
import { IconRevert } from '@consta/uikit/IconRevert';
import { useGetSuggestionItems } from '../../../../../../hooks/useGetSuggestionItems';
import { Modal } from '@consta/uikit/Modal';
import { formatDateHHMMDDMMYYYY } from '../../../../../../utils/formatDateHHMMDDMMYYYY';
import { Avatar } from '@consta/uikit/Avatar';
import { useNavigate } from 'react-router-dom';

interface IAdminBlogTableActionsProps {
  item: any;
}

const AdminSuggestionTableActions: React.FC<IAdminBlogTableActionsProps> = ({ item }) => {
  console.log(item);
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const statuses = useAppSelector(selectorSuggestionStatuses);
  //const getDigest = useGetDigestItems();
  const getSuggestion = useGetSuggestionItems();
  const [isOpenDeleteModal, setIsOpenDeleteModal] = useState(false);
  const inArchive = useAppSelector(selectorViewSelectorInArchive);
  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);

  const onDelete = (uuid: string) => {
    dispatch(deleteSuggestionThunk(item._uuid)).then(() => {
      setIsOpenDeleteModal(false);
      dispatch(getAdminBlogSuggestionThunk()).then();
      dispatch(
        pushSnackBarItemReports({
          key: Math.random(),
          message: `Предложение удалено!`,
          status: 'success',
          autoClose: 5,
        })
      );
    });
  };

  const onArchive = () => {
    dispatch(archiveSuggestionThunk(item._uuid)).then((data) => {
      getSuggestion({});
    });
  };
  const openModal = useCallback(() => {
    setIsModalOpen(true);

    const in_progress: any = statuses.find((el: any) => el.slug === 'in_progress');
    dispatch(
      updateSuggestionThunk({
        uuid: item._uuid,
        data: {
          value: item.value,
          type: item.type,
          uuid_person: item.author.uuid,
          is_speaker: !!item.is_speaker,
          uuid_dict_status: in_progress.uuid,
        },
      })
    ).then(() => {
      dispatch(getAdminBlogSuggestionThunk());
    });
  }, [item, statuses]);
  return (
    <div className={classes.container}>
      <Button size="xs" view="ghost" iconLeft={IconInfo} form={'round'} onlyIcon onClick={openModal} />
      {!inArchive && <Button size="xs" view="ghost" iconLeft={IconStorage} onlyIcon onClick={onArchive} />}
      {inArchive && <Button size="xs" view="ghost" iconLeft={IconRevert} onlyIcon onClick={onArchive} />}
      <Button size="xs" view="ghost" iconLeft={IconTrash} onlyIcon onClick={() => setIsOpenDeleteModal(true)} />
      <BlogModalDelete
        isModalOpen={isOpenDeleteModal}
        setIsModalOpen={setIsOpenDeleteModal}
        modalDeleteTitle={'Вы действительно хотите удалить предложение?'}
        onConfirmDelete={() => onDelete(item._uuid)}
      />

      <Modal
        isOpen={isModalOpen}
        className={classes.reportsModal}
        hasOverlay
        onClickOutside={() => setIsModalOpen(false)}
        onEsc={() => setIsModalOpen(false)}
      >
        <div className={classes.addSubscribers}>
          <div className={classes.formTitle}>Предпросмотр предложения</div>
          <div className={classes.formContent}>
            <div className={classes.col2}>
              <div className={classes.item}>
                <div className={classes.itemTitle}>Тип</div>
                <div className={classes.desc}>{item.type === 'news' ? 'Новость' : 'Анонс'}</div>
              </div>
              <div className={classes.item}>
                <div className={classes.itemTitle}>Дата и время заявки</div>
                <div className={classes.desc}>
                  <div className={classes.dataTime}>{formatDateHHMMDDMMYYYY(new Date(item._create_ts))}</div>
                </div>
              </div>
              <div className={classes.item}>
                <div className={classes.itemTitle}>Автор</div>
                <div className={classes.desc}>
                  <div className={classes.author}>
                    <Avatar
                      className={classes.avatar}
                      url={item.author?.icon ? 'data:image/png;base64,' + item.author?.icon : ''}
                      name={item.author?.last_name + ' ' + item.author?.first_name + ' ' + item.author?.middle_name}
                    />
                    <div>
                      <div className={classes.authorName}>{item.author?.last_name + ' ' + item.author?.first_name}</div>
                      <div className={classes.authorEmail}>{item.author?.email}</div>
                    </div>
                  </div>
                </div>
              </div>
              {item.type !== 'news' && (
                <div className={classes.item}>
                  <div className={classes.itemTitle}>Роль</div>
                  <div className={classes.desc}>{item.is_speaker ? 'Спикер' : 'Зритель'}</div>
                </div>
              )}
            </div>
            <div className={classes.item}>
              <div className={classes.itemTitle}>Предложение</div>
              <div className={classes.desc}>{item.value}</div>
            </div>
          </div>
          <div className={classes.formFooter}>
            <Button
              view={'ghost'}
              size={'m'}
              label={'Написать автору'}
              onClick={() => window.open(`mailto:${item.author.email}`)}
            />
            <Button
              view={'primary'}
              size={'m'}
              label={'Создать новость'}
              onClick={() => navigate(`/admin/blog/news/add?type=${item.type || ''}&value=${item.value}`)}
            />
          </div>
        </div>
      </Modal>
    </div>
  );
};

export default AdminSuggestionTableActions;
