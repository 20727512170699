import { SORTING_ITEMS } from '../../Pages/Admin/AdminBlogPage/AdminBlogPageHeader/AdminBlogPageActionRow/SortingSelector/SORTING_ITEMS';
import { SORTING_ITEMS_DIGEST } from '../../Pages/Admin/AdminBlogPage/AdminBlogPageHeader/AdminBlogPageActionRow/SortingSelector/SORTING_ITEMS_DIGEST';
import { SORTING_ITEMS_SUGGESTION } from '../../Pages/Admin/AdminBlogPage/AdminBlogPageHeader/AdminBlogPageActionRow/SortingSelector/SORTING_ITEMS_SUGGESTION';
import { SORTING_ITEMS as SORTING_ITEMS_PROCESS } from '../../Pages/Admin/AdminMethodologyPage/AdminMethodologyPageHeader/AdminBlogPageActionRow/SortingSelector/SORTING_ITEMS';
import { SORTING_ITEMS_DOCUMENTS } from '../../Pages/Admin/AdminMethodologyPage/AdminMethodologyPageHeader/AdminBlogPageActionRow/SortingSelector/SORTING_ITEMS_DOCUMENTS';

export const getQuerySortValue = (activeSortingItemId?: number | undefined, slug = 'news') => {
  if (activeSortingItemId) {
    let activeSortingItem;

    if (slug === 'news') {
      activeSortingItem = SORTING_ITEMS.find((item) => item.id === activeSortingItemId);
    } else if (slug === 'process') {
      activeSortingItem = SORTING_ITEMS_DIGEST.find((item) => item.id === activeSortingItemId);
    } else if (slug === 'suggestion') {
      activeSortingItem = SORTING_ITEMS_SUGGESTION.find((item) => item.id === activeSortingItemId);
    } else if (slug === 'process') {
      activeSortingItem = SORTING_ITEMS_PROCESS.find((item) => item.id === activeSortingItemId);
    } else if (slug === 'document') {
      activeSortingItem = SORTING_ITEMS_DOCUMENTS.find((item) => item.id === activeSortingItemId);
    }

    return activeSortingItem ? activeSortingItem.sortParams : '';
  }
  return ``;
};
