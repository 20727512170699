import React from 'react';

import TableWrapper from '../../../../../Components/TableWrapper/TableWrapper';
import { useAppSelector } from '../../../../../hooks/hooks';
import { Table } from '../../../../../shared-front/Table/Table';
import { selectorViewSelectorBlogItems } from '../../../../../store/adminBlogSlice';
import { formatDateHHMMDDMMYYYY } from '../../../../../utils/formatDateHHMMDDMMYYYY';
import classes from './AdminDigestTable.module.css';
import AdminDigestTableActions from './AdminDigestTableActions/AdminDigestTableActions';

import { ADMIN_BLOG_TABLE_COLUMNS, AdminBlogDigestDataType } from './adminDigestTableColumns';
import AdminBlogTableBadge from '../AdminBlogTable/AdminBlogTableBadge/AdminBlogTableBadge';
import AdminBlogTableCoverImg from '../AdminBlogTable/AdminBlogTableCoverImg/AdminBlogTableCoverImg';

interface IAdminBlogTableProps {}

const AdminDigestTable: React.FC<IAdminBlogTableProps> = () => {
  const blogItems = useAppSelector(selectorViewSelectorBlogItems)?.data;

  const tableData: AdminBlogDigestDataType[] =
    blogItems?.map((item: any) => {
      return {
        status: <AdminBlogTableBadge status={item.status} />,
        cover: <AdminBlogTableCoverImg imgUrl={item.cover} />,
        name: item.name ?? '',
        file: (
          <a href={item.file} target="_blank">
            {item.file_params?.name}
          </a>
        ),
        date: <div className={classes.dataTime}>{formatDateHHMMDDMMYYYY(new Date(item._create_ts))}</div>,
        actions: <AdminDigestTableActions item={item} />,
      };
    }) || [];

  return (
    <div className={classes.container}>
      <TableWrapper height={'100%'}>
        <Table data={tableData} columns={ADMIN_BLOG_TABLE_COLUMNS} isColumnsResizable={true} />
      </TableWrapper>
    </div>
  );
};

export default AdminDigestTable;
