import React, { useState } from 'react';

import classes from './BlogSubscriptionMailBlock.module.css';
import ModalSubscriptionMail from './ModalSubscriptionMail/ModalSubscriptionMail';
import { Button } from '@consta/uikit/Button';
import { IconMail } from '@consta/uikit/IconMail';
import { Text } from '@consta/uikit/Text';
import { useAppDispatch, useAppSelector } from '../../hooks/hooks';
import { getUserInfoThunk, selectorUserInfo, userToggleSubscribe } from '../../store/appSlice';

const BlogSubscriptionMailBlock = () => {
  const dispatch = useAppDispatch();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const userInfo = useAppSelector(selectorUserInfo);
  const [isSubscribed, setIsSubscribed] = useState<boolean>(userInfo.is_news_subscriber);
  /// console.log(userInfo);
  const closeModal = () => {
    setIsModalOpen(false);
  };

  const onClickSubscribe = () => {
    dispatch(userToggleSubscribe()).then(() => {
      setIsModalOpen(true);
      setIsSubscribed(!isSubscribed);
    });
  };

  return (
    <div className={classes.container}>
      <Text className={classes.title}>{`Наши новости на вашей почте`}</Text>
      <Text
        className={classes.subtitle}
      >{`Подпишитесь на рассылку, чтобы первым узнавать о новых статьях, кейсах и анонсах.\n\nОтправим только ценное, без спама.`}</Text>
      <div>
        <Button
          label={isSubscribed ? 'Отписаться' : `Подписаться`}
          className={classes.btnReadMore}
          iconLeft={IconMail}
          view={isSubscribed ? 'ghost' : 'primary'}
          disabled={true}
          onClick={onClickSubscribe}
        />
      </div>
      <ModalSubscriptionMail subscribe={isSubscribed} isOpen={isModalOpen} closeModal={closeModal} />
    </div>
  );
};

export default BlogSubscriptionMailBlock;
