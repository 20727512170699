import React, { useCallback, useEffect, useState } from 'react';

import classes from './SuggestionFilterFormComponent.module.css';
import { Button } from '@consta/uikit/Button';
import { CheckboxGroup } from '@consta/uikit/CheckboxGroup';
import { useAppDispatch, useAppSelector } from '../../../../../../hooks/hooks';
import {
  getSuggestionTypesThunk,
  selectorSuggestionStatuses,
  selectorSuggestionTypes,
  setSuggestionFilterValue,
} from '../../../../../../store/adminBlogSlice';
import { useGetSuggestionItems } from '../../../../../../hooks/useGetSuggestionItems';

type Item = {
  label: string;
  id: number;
};
type PropsType = {
  closeSideBar: () => void;
};

const SuggestionFilterFormComponent: React.FC<PropsType> = ({ closeSideBar }) => {
  const dispatch = useAppDispatch();
  const statuses = useAppSelector(selectorSuggestionStatuses);
  const types = useAppSelector(selectorSuggestionTypes);
  const getSuggestionItems = useGetSuggestionItems();
  const [statusValue, setStatusValue] = useState<any[] | null>();
  const [typeValue, setTypeValue] = useState<any[] | null>();
  const setFilter = useCallback(() => {
    const tmp: string[] = [];
    if (statusValue && statusValue.length) {
      statusValue.map((el: any) => {
        tmp.push(`filter[status][]=${el.uuid}`);
      });
    }
    if (typeValue && typeValue.length) {
      typeValue.map((el: any) => {
        tmp.push(`filter[type][]=${el.slug}`);
      });
    }
    dispatch(setSuggestionFilterValue(tmp.join('&')));
    getSuggestionItems({ filterValue: tmp.join('&') });
  }, [typeValue, statusValue]);
  useEffect(() => {
    dispatch(getSuggestionTypesThunk());
    dispatch(getSuggestionTypesThunk());
  }, []);
  return (
    <div className={classes.sidebarFilter}>
      <div className={classes.sidebarTitle}>
        <div>Фильтры</div>
        <Button view={'ghost'} label={'Сбросить фильтры'} size={'s'} />
      </div>
      <div className={classes.sidebarItem}>
        <div className={classes.itemTitle}>
          Статус{' '}
          <span className={classes.clear} onClick={() => setStatusValue(null)}>
            Сбросить
          </span>
        </div>
        <div className={classes.checkboxGroup}>
          <CheckboxGroup
            value={statusValue}
            items={statuses}
            getItemLabel={(item: any) => item.name}
            onChange={(value) => {
              setStatusValue(value.value);
            }}
            name="CheckboxGroup"
            direction="column"
            size="m"
          />
        </div>
      </div>

      <div className={classes.sidebarItem}>
        <div className={classes.itemTitle}>
          Тип{' '}
          <span className={classes.clear} onClick={() => setStatusValue(null)}>
            Сбросить
          </span>
        </div>
        <div className={classes.checkboxGroup}>
          <CheckboxGroup
            value={typeValue}
            items={types}
            getItemLabel={(item: any) => item.name}
            onChange={(value) => {
              setTypeValue(value.value);
            }}
            name="CheckboxGroup"
            direction="column"
            size="m"
          />
        </div>
      </div>

      <div className={classes.bottomButtons}>
        <div></div>
        <div>
          <Button size="m" view="ghost" form={'default'} label={'Отмена'} onClick={closeSideBar} />
          <Button size="m" view="primary" form={'default'} label={'Применить'} onClick={setFilter} />
        </div>
      </div>
    </div>
  );
};

export default SuggestionFilterFormComponent;
