import React, { useEffect } from 'react';

import ModalError from '../../../Components/ModalError/ModalError';
import { useAppDispatch, useAppSelector } from '../../../hooks/hooks';
import {
  getAdminBlogDigestThunk,
  getAdminBlogItemsThunk,
  getAdminBlogSubscriptionThunk,
  getAdminBlogSuggestionThunk,
  selectorErrorAdminBlogPage,
  selectorViewSelectorActiveItem,
} from '../../../store/adminBlogSlice';
import classes from './AdminBlogPage.module.css';
import AdminBlogPageContent from './AdminBlogPageContent/AdminBlogPageContent';
import AdminBlogPageHeader from './AdminBlogPageHeader/AdminBlogPageHeader';
import AdminBlogPagePaginationRow from './AdminBlogPagePaginationRow/AdminBlogPagePaginationRow';

interface IAdminBlogPageProps {}

const AdminBlogPage: React.FC<IAdminBlogPageProps> = () => {
  const dispatch = useAppDispatch();
  const errorAdminBlogPage = useAppSelector(selectorErrorAdminBlogPage);
  const view = useAppSelector(selectorViewSelectorActiveItem);

  useEffect(() => {
    if (view?.slug === 'news') {
      dispatch(getAdminBlogItemsThunk(`?sort=-publication_date&page[size]=25&page[number]=1&filter[in_archive]=false`));
    } else if (view?.slug === 'subscription') {
      dispatch(getAdminBlogSubscriptionThunk(`?sort=-fio&page[size]=25&page[number]=1`));
    } else if (view?.name === 'digest') {
      dispatch(getAdminBlogDigestThunk(`?page[size]=25&page[number]=1`));
    } else {
      dispatch(getAdminBlogSuggestionThunk(`?sort=-_create_ts&page[size]=25&page[number]=1`));
    }
  }, [dispatch, view]);

  return (
    <div className={classes.container}>
      <AdminBlogPageHeader />
      <AdminBlogPageContent />
      <AdminBlogPagePaginationRow />
      <ModalError errorMessage={errorAdminBlogPage} />
    </div>
  );
};

export default AdminBlogPage;
